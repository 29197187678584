import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink, generatePath } from 'react-router-dom';
import useConfig from 'app/hooks/useConfig';
import ImageWithLoader from 'app/components/ImageWithLoader';
import TitleWithPoster from './styles';
import { useState } from 'react';

export const toMinutes = time => {
  const h = Math.floor(time / 60);
  const m = time - 60 * h > 0 ? time - 60 * h : '';

  return `${h}h${m ? ` ${m}m` : ''}`;
};

const propTypes = {
  date: PropTypes.string,
  location: PropTypes.string,
  entityDetails: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    titleCalculated: PropTypes.string,
    poster: PropTypes.string,
    posterImage: PropTypes.string,
    isInWatchlist: PropTypes.bool,
    genres: PropTypes.oneOfType([PropTypes.array, PropTypes.any]),
    runTime: PropTypes.number
  }),
  withEvent: PropTypes.shape({
    dates: PropTypes.string
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

const defaultProps = {
  date: null,
  location: null,
  entityDetails: null,
  children: null,
  withEvent: null
};

const getFields = (entity, withEvent) => {
  if (withEvent) {
    return {
      id: entity.id,
      title: entity.title,
      poster: entity.poster,
      isInWatchlist: entity.isInWatchlist
    };
  }

  return {
    id: entity.id,
    title: entity.titleCalculated,
    poster: entity.posterImage,
    isInWatchlist: entity.isInWatchlist,
    genres: entity.genres ? entity.genres.join(', ') : null,
    runTime: toMinutes(entity.runTime),
    shortURL: entity.shortURL
  };
};

const TitleWithPosterComponent = ({ date, entityDetails, location, withEvent, children, cinemaId }) => {
  const [t] = useTranslation();
  const { routes } = useConfig();
  const { id, title, poster, genres, runTime, shortURL } = getFields(entityDetails, withEvent);
  const [windowSize, setWindowSize] = useState();
  window.addEventListener('resize', function () {
    setWindowSize(window.innerWidth);
  }, true)
  return (
    <TitleWithPoster>
      <div className={windowSize <= 992 ? "mobile-view-poster" : "desktop-view-poster"}>
        {date ? (
          <ImageWithLoader
            as={NavLink}
            to={{
              pathname: generatePath(routes.movieDetails.pattern, { id: shortURL ? shortURL : title.replaceAll(' ', '-') }),
              search: `?date=${date}&location=${location}`,
              state: { movieId: id, cinemaId: cinemaId }
            }}
            imgSrc={poster}
            imgTitle={title}
          />
        ) : (
          <ImageWithLoader imgSrc={poster} imgTitle={title || ''} />
        )}
        <div className="b-title-with-poster__inner">
          <h2 className="b-title-with-poster__title">{title || t('movies.no_title')}</h2>
          <div className='b-title-with-poster__duration-genere-group'>
            {genres ? <p className="b-title-with-poster__genre">{genres}</p> : null}
            <p className='b-title-with-poster__comma b-title-with-poster__duration'>&nbsp;</p>
            {runTime ? <p className="b-title-with-poster__duration">{runTime}</p> : null}
          </div>
          {withEvent ? <p className="b-title-with-poster__duration">{withEvent.dates}</p> : null}
          {children}
        </div>
      </div>
    </TitleWithPoster>
  );
};

TitleWithPosterComponent.propTypes = propTypes;
TitleWithPosterComponent.defaultProps = defaultProps;
export default TitleWithPosterComponent;

import routes from './routes';
import logo from './logo';

export default (apiEndpoint, apiIalEndpoint, cmsApiEndpoint, googleAnalyticKey, googleMapKey, gtmId,
  // giftCardShop, 
  currencyForCountry,
  giftCardShopEN, giftCardShopDE, streamingUrl, vodNotLoggedInUrlEN,
  vodNotLoggedInUrlDE, memberLevelId, clubID, domainGroupId, cineplexUrl, surveyURL, sitekey, secretKey) => (
  {
    rootElementId: 'vapc',
    country: null,
    intl: {
      fallbackLng: 'en',
      locales: [
        {
          locale: 'de',
          label: 'Deutsch'
        },
        {
          locale: 'en',
          label: 'English'
        }
      ]
    },
    api: {
      endpoint: apiEndpoint,
      cmsEndpoint: cmsApiEndpoint,
      version: '/api/v1',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'CINEPLEXX-Platform': 'WEB',
        'client-key': `${apiIalEndpoint}`,

      },
      user: {
        workZipCode: null
      }
    },

    cmsApi: {
      endpoint: cmsApiEndpoint,
      version: '/restV',
      headers: {
        Authorization: 'Bearer YPJen3UoCRmKx4aC6oYv5DJ1nwoP0O1tsThFAm41NRT8Uixk9JW8nf4bWc5KAquh4Z9Cbv3F34OiN2Db'
      },
      user: {
        workZipCode: null
      }
    },

    media: {
      imgPrefix: url => url,
      docsPrefix: url => `${apiEndpoint}/api/v1/media/files?path=${url}`
    },

    bonusCard: {
      enabled: false
    },

    browserError: 'Index occurs',
    googleMapKey,
    googleAnalyticKey,
    gtmId,
    domainGroupId,
    cineplexUrl,
    surveyURL,
    sitekey,
    secretKey,
    // giftCardShop,
    giftCardShopEN,
    giftCardShopDE,
    currencyForCountry,
    streamingUrl,
    vodNotLoggedInUrlEN,
    vodNotLoggedInUrlDE,
    memberLevelId,
    clubID,
    routes,
    serverTimeZone: null,

    footer: {
      copyright: '© Cinepromotion und Filmmarketing Ges.m.b.H. 2019',
      apps: {
        android: 'https://play.google.com/store/apps/details?id=at.cineplexx.app',
        ios: 'https://itunes.apple.com/at/app/cineplexx-at/id1457257579'
      },
      buttons: {
        legalInfo1: 'https://anpc.ro/ce-este-sal/',
        legalInfo2: 'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO'
      }
    },

    supportInfo: {
      contactUs: {
        email: 'cineplexx@constantinfilm.at'
      }
    },

    form: {
      validation: {
        zip: null
      }
    },

    logo,

    companyName: 'Cineplexx',

    player: {
      jquery: '//cdnjs.cloudflare.com/ajax/libs/jquery/1.9.1/jquery.js',
      url: '//uvp-cineplexx.sf.apa.at/scripts/jquery.uvp.stable.js'
    },

    headerTypes: {
      ACCEPT_LANGUAGE: 'Accept-Language',
      AUTHORIZATION: 'Authorization',
      CONTENT_TYPE: 'Content-Type',
      CLIENT_KEY: 'client-key'
    }
  });
